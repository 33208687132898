<script>
  import Button from "./Button.svelte";
  import Dialog from "./Dialog.svelte";

  export let onDelete;
  export let loading;
  let dialog;
  let state;
  let message = "Are you sure you want to delete this item?";

  export function open(st, msg) {
    state = st;
    if (msg) {
      message = msg;
    }
    dialog.open();
  }

  export function close() {
    state = null;
    dialog.close();
  }

  function onCancel() {
    dialog.close();
  }
</script>

<Dialog bind:this={dialog}>
  <p>{message}</p>

  <div class="flex mt-8">
    <button class="px-4 mr-4" on:click={onCancel}>Cancel</button>
    <Button variant="danger" {loading} onClick={() => onDelete(state)}
      >Delete</Button
    >
  </div>
</Dialog>

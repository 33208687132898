<script>
  import { Router, Route } from "svelte-routing";
  import Notes from "./routes/Notes.svelte";
  import Trips from "./routes/Trips.svelte";
  import Login from "./routes/Login.svelte";
  import Logout from "./routes/Logout.svelte";
  import Header from "./components/Header.svelte";
  import Tailwind from "./components/Tailwind.svelte";

  export let url = "";
  export let data = null;
</script>

<Router {url}>
  <Tailwind />
  <Header />
  <main>
    <Route path="/logout" component={Logout} {data} />
    <Route path="/notes" component={Notes} />
    <Route path="/notes/:noteId" component={Notes} />

    <Route path="/trips" component={Trips} />
    <Route path="/trips/:tripId" component={Trips} />

    <Route path="/" component={Login} {data} />
  </main>
</Router>

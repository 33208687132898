<script>
  import { onMount } from 'svelte';

  let dialog;
  export function open() {
    dialog.showModal();
  }

  export function close() {
    dialog.close();
  }

  onMount(async () => {
    if('show' in document.createElement('dialog') === false) {
      const dialogPolyfill = await import("dialog-polyfill");
      dialogPolyfill.default.registerDialog(dialog);
    }
  });
</script>

<dialog bind:this={dialog}>
  <div class="close-button" on:click={close}>X</div>
  <slot />
</dialog>

<style global>
  .close-button {
    text-align: right;
    cursor: pointer;
  }

  dialog {
    position: absolute;
    left: 0;
    right: 0;
    min-width: 40%;
    margin: auto;
    border: solid 2px #ddd;
    border-radius: 8px;
    background-color: #eee;
    width: fit-content;
    height: fit-content;
    padding: 1em;
    background: white;
    display: block;
  }

  @media (max-width: 1024px) {
    dialog {
      min-width: 90%;
    }
  }

  dialog:not([open]) {
    display: none;
  }

  dialog + .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  ._dialog_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
</style>

<script context="module">
  // retain module scoped expansion state for each tree node
  const _expansionState = {
    /* treeNodeId: expanded <boolean> */
  };
</script>

<script>
  import { links } from "svelte-routing";
  export let tree;
  export let baseUrl = "/";
  const { label, children, id } = tree;

  let expanded = _expansionState[label] || false;
  const toggleExpansion = () => {
    expanded = _expansionState[label] = !expanded;
  };
  $: arrowDown = expanded;
</script>

<ul use:links>
  <li>
    {#if children}
      <span class="cursor-pointer" on:click={toggleExpansion}>
        <span class="arrow" class:arrowDown>&#x25b6</span>
        {label}
      </span>
      {#if expanded}
        {#each children as child}
          <svelte:self tree={child} {baseUrl} />
        {/each}
      {/if}
    {:else}
      <span>
        <span class="no-arrow" />
        <a href={`${baseUrl}${id}`}>{label}</a>
      </span>
    {/if}
  </li>
</ul>

<style>
  ul {
    margin: 0;
    list-style: none;
    user-select: none;
  }
  .no-arrow {
    padding-left: 1rem;
  }
  .arrow {
    display: inline-block;
  }
  .arrowDown {
    transform: rotate(90deg);
  }
</style>

<script>
  import { createEventDispatcher } from "svelte";

  export let isOpen = false;

  const dispatch = createEventDispatcher();
</script>

<div class="sm:hidden">
  <button
    on:click={() => dispatch('click')}
    class="block text-white hover:text-white focus:text-white focus:outline-none">
    <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
      <path
        class:hidden={isOpen}
        fill-rule="evenodd"
        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0
        1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />

      <path
        class:hidden={!isOpen}
        fill-rule="evenodd"
        d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0
        1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828
        4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
    </svg>
  </button>
</div>

<script>
  export let categories = [];
  export let value;
  export let loading = false;

  let state = {};

  function categoryChanged(ev) {
    if (ev.target.value === "-1") {
      state.addCategory = true;
      value = "";
    } else {
      state.addCategory = false;
    }
  }
</script>

{#if categories.length}
  <label class="block">
    <span class="text-gray-700">Category</span>
    <!-- svelte-ignore a11y-no-onchange -->
    <select
      class="form-input"
      bind:value
      required={!state.addCategory}
      on:change={categoryChanged}
      disabled={loading}
    >
      <option value="">Select category</option>
      {#each categories as category}
        <option value={category}>{category}</option>
      {/each}
      <option value="-1">New category</option>
    </select>
  </label>
{/if}

{#if state.addCategory || !categories.length}
  <label class="block">
    <span class="text-gray-700">New Category</span>
    <input
      type="text"
      class="form-input"
      bind:value
      required
      disabled={loading}
    />
  </label>
{/if}

<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import * as  api from 'src/lib/api';
  import { nanoid } from "nanoid";
  import Button from "src/components/Button.svelte";
  import Dialog from "src/components/Dialog.svelte";
  import MarkdownEditor from "src/components/Markdown-Editor.svelte";
  import DragAndDropUpload from "src/components/Drag-And-Drop-Upload.svelte";
  import Confirm from "src/components/Confirm.svelte";
  import { Plus, Edit, Delete, Download } from "src/icons";
  import download from "src/lib/client-side-download";
  
  export let tripId;

  let trips = [];
  let trip;
  let state = {loading: false, files: []};
  let addDialog;
  let confirmFileDeletionDialog;
  let confirmTripDeletionDialog;

  onMount(async () => {
    await loadTrips();
  });

  $: if (tripId) {
    loadTrip();
  }

  async function loadTrips() {
    const res = await api.get('/trips');
    trips = Object.values(res.trips);
  }
  
  async function loadTrip() {
    state = {};
    state.loading = true;

    try {
      const res = await api.get(`/trips/${tripId}`);
      trip = res.trip;
    } catch (err) {
      if (err.status === 401) {
        navigate("/");
      }
      else if(err.status === 404) {
        navigate('/trips');
      }
      else {
        throw err;
      }
    }
    state.loading = false;
  }

  function add() {
    state = { id: nanoid(), files: [] };
    addDialog.open();
  }

  function cancelAdd() {
    addDialog.close();
  }

  async function submitAdd() {
    state.loading = true;
    await api.post("/trips", { trip: state });
    addDialog.close();
    navigate(`/trips/${state.id}`);
    state = {};
  }

  async function deleteFile(file) {
    let msg = "Are you sure you want to delete this file?";
    if (new RegExp(file.key).test(trip.trip_text)) {
      msg = `The markdown contains this file "${file.name}". Are you sure you want to delete it?`;
    }
    confirmFileDeletionDialog.open(Object.assign({}, file), msg);
  }

  async function deleteFileConfirmed(file) {
    await api.del(`/trips/${tripId}/${file.key}`);
    confirmFileDeletionDialog.close();
    await loadTrip();
  }
  
  function downloadMd() {
    download(trip.trip_title.toLowerCase().replace(/\s+/, "-"), trip.trip_text);
  }
  
  function edit() {
    state = Object.assign({}, trip);
    state.edit = true;
  }

  function cancelEdit() {
    state.edit = false;
  }
  
  async function submitEdit() {
    state.loading = true;
    await api.put(`/trips/${tripId}`, { trip: state });
    await loadTrip();
    state.edit = false;
    state.loading = false;
  }
  
  function delTrip() {
    state = Object.assign({}, trip);
    confirmTripDeletionDialog.open(state);
  }
  
  async function deleteTripConfirmed(t) {
    state.loading = true;
    await api.del(`/trips/${t.id}`);
    navigate("/trips");
  }

</script>

<div class="grid grid-cols-12">
  <div class="col-span-2 p-4">
    <button on:click={add}>Add</button>
    {#each trips as trip}
      <div>
        <a href="/trips/{trip.id}">{trip.trip_title}</a>
      </div>
    {/each}
  </div>
  <div class="col-span-10 p-4">
    {#if trip && !state.edit}
      <div class="lg:flex justify-between items-center">
        <h1 class="mb-0">{@html trip.trip_title}</h1>
        <div class="mb-8 lg:mb-0">
          <button on:click={add}><Plus /></button>
          <button on:click={downloadMd}><Download /></button>
          <button class="no-print" on:click={edit}><Edit /></button>
          <button class="no-print text-red-500" on:click={delTrip}
            ><Delete /></button
          >
        </div>
      </div>

      {@html trip.html}

      {#if trip.files?.length}
        <h3>Files</h3>
        <ul>
          {#each trip.files as file}
            <li>
              <a href={file.url} target="_blank">{file?.name}</a>
              <button
                class="no-print text-red-500"
                on:click={() => deleteFile(file)}><Delete /></button
              >
            </li>
          {/each}
        </ul>
      {/if}

      <DragAndDropUpload
        uploadPath="/trips/{tripId}/upload"
        on:done={() => loadTrip()}
      />
    {/if}

    {#if state.edit}
      <form on:submit|preventDefault={submitEdit}>
        <div class="grid grid-cols-1 gap-6">
          <label class="block">
            <span class="text-gray-700">Title</span>
            <input
              type="text"
              class="form-input"
              bind:value={state.trip_title}
              required
              disabled={state.loading}
            />
          </label>

          <MarkdownEditor
            label="Details"
            uploadPath="/trips/{state.id}/upload"
            loading={state.loading}
            bind:value={state.trip_text}
          />

          <div class="flex">
            <Button loading={state.loading}>Save</Button>
            <Button variant="secondary" type="button" onClick={cancelEdit}
              >Cancel</Button
            >
          </div>
        </div>
      </form>
    {/if}
  </div>
</div>

<Dialog bind:this={addDialog}>
  <form on:submit|preventDefault={submitAdd}>
    <div class="grid grid-cols-1 gap-6">
      <label class="block">
        <span class="text-gray-700">Title</span>
        <input
          type="text"
          class="form-input"
          bind:value={state.trip_title}
          required
          disabled={state.loading}
        />
      </label>

      <MarkdownEditor
        label="Details"
        uploadPath="/trips/{state.id}/upload"
        loading={state.loading}
        bind:value={state.trip_text}
      />

      <div class="flex">
        <Button loading={state.loading}>Save</Button>
        <Button variant="secondary" type="button" onClick={cancelAdd}
          >Cancel</Button
        >
      </div>
    </div>
  </form>
</Dialog>

<Confirm
  bind:this={confirmFileDeletionDialog}
  onDelete={deleteFileConfirmed}
  loading={state.loading}
/>

<Confirm
  bind:this={confirmTripDeletionDialog}
  onDelete={deleteTripConfirmed}
  loading={state.loading}
/>

<script>
  import Button from "src/components/Button.svelte";
  import * as api from "src/lib/api";
  import { navigate } from "svelte-routing";

  let state = { loading: false, username: "", password: "" };

  async function submit() {
    state.loading = true;
    try {
      const { token } = await api.post("/auth", {
        username: state.username,
        password: state.password,
      });
      window.localStorage.setItem("authToken", token);

      navigate("/notes");
    } catch (error) {
      state.error = "Invalid username or password";
      window.localStorage.removeItem("authToken");
      state.password = "";
      state.loading = false;
    }
  }

  function onFocus() {
    state.error = "";
  }
</script>

<div class="flex items-center justify-center w-full mt-8 lg:h-screen lg:mt-0">
  <div class="bg-gray-200 rounded p-8 w-96">
    <form on:submit|preventDefault={submit}>
      <div class="grid grid-cols-1 gap-6">
        <label class="block">
          <span class="text-gray-700">Username</span>
          <input
            type="text"
            class="form-input"
            bind:value={state.username}
            required
            disabled={state.loading}
            on:focus={onFocus}
            autocapitalize="off"
          />
        </label>
        <label class="block">
          <span class="text-gray-700">Password</span>
          <input
            type="password"
            class="form-input"
            bind:value={state.password}
            required
            disabled={state.loading}
            on:focus={onFocus}
          />
        </label>

        {#if state.error}
          <div class="bg-red-500 text-white p-2 mb-2 rounded">
            {state.error}
          </div>
        {/if}

        <Button loading={state.loading}>Sign in</Button>
      </div>
    </form>
  </div>
</div>

<script>
  import { createEventDispatcher } from "svelte";
  import * as api from "src/lib/api";

  const dispatch = createEventDispatcher();

  export let fullPage = false;
  export let uploadPath = "";

  let uploading = false;

  async function dropHandler(ev) {
    ev.preventDefault();

    uploading = true;

    const uploads = [];

    for (const item of ev.dataTransfer.items) {
      if (item.kind === "file") {
        const file = item.getAsFile();
        if(/^image/.test(file.type)) {
          uploads.push(api.postImage(uploadPath, file));
        }
        else {
          uploads.push(api.postFile(uploadPath, file));
        }
        
      }
    }

    await Promise.all(uploads);

    dispatch("done");
    uploading = false;
  }

  function dragOverHandler(ev) {
    ev.preventDefault();
  }
</script>

{#if uploading}
  <h3>Uploading...</h3>
{:else if fullPage}
  <div class="full-page" on:drop={dropHandler} on:dragover={dragOverHandler} />
{:else}
  <div
    class="border-blue-400 border-dashed border-4 p-4 mt-8 w-full"
    on:drop={dropHandler}
    on:dragover={dragOverHandler}
  >
    <p>Drag files here to upload...</p>
  </div>
{/if}

<style>
  .full-page {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
</style>

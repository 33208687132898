<script>
  import { onMount } from "svelte";
  import { navigate, links } from "svelte-routing";
  import { nanoid } from "nanoid";
  import * as api from "src/lib/api";
  import Button from "src/components/Button.svelte";
  import Dialog from "src/components/Dialog.svelte";
  import MarkdownEditor from "src/components/Markdown-Editor.svelte";
  import DragAndDropUpload from "src/components/Drag-And-Drop-Upload.svelte";
  import CategorySelector from "src/components/Category-Selector.svelte";
  import Confirm from "src/components/Confirm.svelte";
  import TreeView from "src/components/TreeView.svelte";
  import { Plus, Edit, Delete, Download } from "src/icons";
  import SearchEngine from "src/lib/search-engine";
  import download from "src/lib/client-side-download";

  export let noteId;
  export let location;

  let state = { loading: false };
  let notes = [];
  let note;
  let categories = [];
  let addDialog;
  let confirmNoteDeletionDialog;
  let confirmFileDeletionDialog;
  let searchEngine;
  let searchInput;

  onMount(async () => {
    await loadSidebar();
    if (location.pathname === "/notes") {
      searchInput.focus();
    }
  });

  $: if (noteId) {
    loadNote();
  }

  $: if (location.search) {
    const params = new URLSearchParams(location.search);
    const q = params.get("q");
    loadNote(q);
  } else if (noteId) {
    loadNote();
  }

  async function loadSidebar() {
    state.loading = true;

    try {
      const res = await api.get("/notes");

      searchEngine = new SearchEngine({
        fields: ["note_title", "note_text"],
        storeFields: ["note_title", "note_text"],
        searchOptions: {
          boost: { note_title: 2 },
          prefix: true,
          fuzzy: 0.2,
        },
      });

      await searchEngine.addAllAsync(res.notes);

      notes = res.tree;
      categories = res.categories;
    } catch (err) {
      if (err.status === 401) {
        navigate("/");
      }
      else if(err.status === 404) {
        navigate('/notes');
      }
      else {
        throw err;
      }
    }

    state.loading = false;
  }

  async function loadNote(q) {
    state = {};
    state.loading = true;

    try {
      const res = await api.get(`/notes/${noteId}`);
      note = res.note;

      if (q) {
        const markRegex = new RegExp(`(${q})`, "ig");
        const markReplacement = "<mark>$1</mark>";
        note.note_title = note.note_title.replace(markRegex, markReplacement);
        note.html = note.html.replace(markRegex, markReplacement);
      }
    } catch (err) {
      if (err.status === 401) {
        navigate("/");
      } else {
        throw err;
      }
    }
    state.loading = false;
  }

  function add() {
    state = { note_category: "", id: nanoid(), files: [] };
    addDialog.open();
  }

  function edit() {
    state = Object.assign({}, note);
    state.edit = true;
  }

  function cancelEdit() {
    state.edit = false;
  }

  function delNote() {
    state = Object.assign({}, note);
    confirmNoteDeletionDialog.open(state);
  }

  async function deleteNoteConfirmed(n) {
    state.loading = true;
    await api.del(`/notes/${n.id}`);
    navigate("/notes");
  }

  async function submitAdd() {
    state.loading = true;
    await api.post("/notes", { note: state });
    await loadSidebar();
    addDialog.close();
    navigate(`/notes/${state.id}`);
    state = {};
  }

  function cancelAdd() {
    state = {};
    addDialog.close();
  }

  async function deleteFile(file) {
    let msg = "Are you sure you want to delete this file?";
    if (new RegExp(file.key).test(note.note_text)) {
      msg = `The markdown contains this file "${file.name}". Are you sure you want to delete it?`;
    }
    confirmFileDeletionDialog.open(Object.assign({}, file), msg);
  }

  async function deleteFileConfirmed(file) {
    await api.del(`/notes/${noteId}/${file.key}`);
    confirmFileDeletionDialog.close();
    await loadNote();
  }

  async function submitEdit() {
    state.loading = true;
    await api.put(`/notes/${noteId}`, { note: state });
    await loadNote();
    state.edit = false;
    state.loading = false;
  }

  async function submitSearch() {
    if (state.search) {
      const searchResults = searchEngine.search(state.search);
      if (searchResults.length === 1) {
        navigate(`/notes/${searchResults[0].id}?q=${state.search}`);
      } else {
        state.searchResults = searchResults;
      }
    }
  }

  function downloadMd() {
    download(note.note_title.toLowerCase().replace(/\s+/, "-"), note.note_text);
  }
</script>

<div class="grid grid-cols-1 lg:grid-cols-12">
  <div
    class="sidebar lg:col-span-2 bg-white lg:bg-gray-200 p-4 no-print"
    class:hidden={notes?.length === 0}
  >
    {#if notes?.length && searchEngine}
      <div class="mb-2">
        <input
          type="text"
          bind:this={searchInput}
          class="form-input rounded-none rounded-t"
          placeholder="Search"
          disabled={state.loading}
          bind:value={state.search}
          on:focus={() => navigate("/notes", { replace: true })}
          on:keyup={(ev) => {
            state.searchResults = null;
            if (ev.key === "Enter") {
              submitSearch();
            }
          }}
        />
      </div>
    {/if}

    {#if notes?.length}
      <div class="flex justify-end">
        <button on:click={add}><Plus />Add new</button>
      </div>
    {/if}
    {#each notes as note}
      <TreeView tree={note} baseUrl="/notes/" />
    {/each}
  </div>

  <div class="lg:col-span-10 p-4">
    {#if state.search && state?.searchResults?.length === 0}
      <div>No results found matching search: {state.search}</div>
    {/if}
    {#each state.searchResults ?? [] as sr}
      <div
        use:links
        class="border p-2 rounded mb-2 cursor-pointer hover:bg-gray-200 hover:shadow"
        on:click={() => navigate(`/notes/${sr.id}?q=${state.search}`)}
      >
        <h3 class="mt-0">
          <a href="/notes/{sr.id}?q={state.search}">{sr.note_title}</a>
        </h3>
        <pre class="search-result-text">{sr.note_text}</pre>
      </div>
    {/each}

    {#if notes?.length === 0}
      <button on:click={add}><Plus /> Add your first note </button>
    {/if}
    {#if note && !state.edit && !state.searchResults}
      <div class="lg:flex justify-between items-center">
        <h1 class="mb-0">{@html note.note_title}</h1>
        <div class="mb-8 lg:mb-0">
          <button on:click={add}><Plus /></button>
          <button on:click={downloadMd}><Download /></button>
          <button class="no-print" on:click={edit}><Edit /></button>
          <button class="no-print text-red-500" on:click={delNote}
            ><Delete /></button
          >
        </div>
      </div>
      <h3 class="mt-0 mb-8">{note.note_category}</h3>

      <section>
        {@html note.html}
      </section>

      {#if note?.files?.length}
        <h3>Files</h3>
        <ul>
          {#each note.files as file}
            <li>
              <a href={file.url} target="_blank">{file?.name}</a>
              <button
                class="no-print text-red-500"
                on:click={() => deleteFile(file)}><Delete /></button
              >
            </li>
          {/each}
        </ul>
      {/if}

      <DragAndDropUpload
        uploadPath="/notes/{noteId}/upload"
        on:done={() => loadNote()}
      />
    {/if}

    {#if state.edit}
      <form on:submit|preventDefault={submitEdit}>
        <div class="grid grid-cols-1 gap-6">
          <label class="block">
            <span class="text-gray-700">Title</span>
            <input
              type="text"
              class="form-input"
              placeholder="Note title"
              bind:value={state.note_title}
              required
              disabled={state.loading}
            />
          </label>

          <CategorySelector
            loading={state.loading}
            bind:value={state.note_category}
            {categories}
          />

          <MarkdownEditor
            label="Note"
            uploadPath="/notes/{noteId}/upload"
            bind:value={state.note_text}
            loading={state.loading}
          />

          <div class="flex">
            <Button loading={state.loading}>Save</Button>
            <Button
              class="ml-2"
              variant="secondary"
              type="button"
              onClick={cancelEdit}>Cancel</Button
            >
          </div>
        </div>
      </form>
    {/if}
  </div>
</div>

<Dialog bind:this={addDialog}>
  <form on:submit|preventDefault={submitAdd}>
    <div class="grid grid-cols-1 gap-6">
      <label class="block">
        <span class="text-gray-700">Title</span>
        <input
          type="text"
          class="form-input"
          bind:value={state.note_title}
          required
          disabled={state.loading}
        />
      </label>

      <CategorySelector
        loading={state.loading}
        bind:value={state.note_category}
        {categories}
      />

      <MarkdownEditor
        label="Note"
        uploadPath="/notes/{state.id}/upload"
        loading={state.loading}
        bind:value={state.note_text}
        on:upload={({ detail: file }) =>
          state.files.push({ key: file.id, name: file.name })}
      />

      <div class="flex">
        <Button loading={state.loading}>Save</Button>
        <Button variant="secondary" type="button" onClick={cancelAdd}
          >Cancel</Button
        >
      </div>
    </div>
  </form>
</Dialog>

<Confirm
  bind:this={confirmNoteDeletionDialog}
  onDelete={deleteNoteConfirmed}
  loading={state.loading}
/>

<Confirm
  bind:this={confirmFileDeletionDialog}
  onDelete={deleteFileConfirmed}
  loading={state.loading}
/>

<style lang="postcss">
  @media (min-width: 1024px) {
    .sidebar {
      min-height: calc(100vh - 48px);
    }
  }

  .search-result-text {
    max-height: 100px;
    overflow-y: auto;
  }
</style>

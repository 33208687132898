import { nanoid } from 'nanoid';
import { load as readExif } from 'exifreader';

class ApiError extends Error {
  constructor({ statusCode, statusText }) {
    super(statusText);
    this.name = 'ApiError';
    this.statusText = statusText;
    this.statusCode = statusCode;
  }
}

function getFullUrl(url) {
  if (/^http/.test(url)) {
    return url;
  }
  return `${API_URL}${url}`;
}

function getHeaders() {
  const headers = {
    'Content-Type': 'application/json',
  };
  const authToken = window.localStorage.getItem('authToken');
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }
  return headers;
}

export async function post(url, data = {}) {
  const response = await fetch(getFullUrl(url), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: getHeaders(),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new ApiError({
      statusCode: response.status,
      statusText: response.statusText,
    });
  }
  return await response.json();
}

export async function put(url, data = {}) {
  const response = await fetch(getFullUrl(url), {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: getHeaders(),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`Error with PUT to ${url}`, response);
  }

  return await response.json();
}

export async function get(url) {
  const response = await fetch(getFullUrl(url), {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: getHeaders(),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  const json = await response.json();

  if (!response.ok) {
    const error = Object.assign({}, json, {
      status: response.status,
      statusText: response.statusText,
    });

    return Promise.reject(error);
  }

  return json;
}

export async function del(url) {
  const response = await fetch(getFullUrl(url), {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: getHeaders(),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  if (!response.ok) {
    throw new Error('Delete failed');
  }
}

export async function postFile(url, file) {
  const fileId = nanoid();
  const headers = getHeaders();
  headers['Content-Type'] = file.type;
  headers['x-key'] = file.name;
  headers['x-id'] = fileId;

  const fileUploadRes = await fetch(getFullUrl(`/files/upload`), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers,
    body: file,
  });

  if (!fileUploadRes.ok) {
    throw new Error(`Error uploading file to /files/upload`, fileUploadRes);
  }

  const { key, name, extension } = await fileUploadRes.json();

  const res = await post(url, {
    fileId,
    key,
    name,
    extension,
  });

  return res;
}

export async function postImage(url, file) {
  const { url: cloudFlareUrl } = await post(`/files/image-upload-url`);

  const tags = await readExif(file, { expanded: true });
  const { Latitude, Longitude, Altitude } = tags.gps || {};
  const dateTaken = tags?.exif?.DateTime?.description;

  const formData = new FormData();
  formData.append('file', file);

  const res = await fetch(cloudFlareUrl, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: formData,
  });

  if (!res.ok) {
    throw new Error(`Error uploading file to ${url}`, res);
  }

  const { result } = await res.json();

  const uploadRes = await post(url, {
    cloudflare: true,
    fileId: result.id,
    key: result.id,
    name: file.name,
    type: file.type,
    latitude: Latitude,
    longitude: Longitude,
    altitude: Altitude,
    dateTaken,
  });

  return uploadRes;
}

import MiniSearch from "minisearch";

export default class SearchEngine {
  constructor(options) {
    this.miniSearch = new MiniSearch(options);
    this.documentsById = {};
  }

  addOne(document) {
    if (this.documentsById[document.id] != null) {
      throw new Error(`Duplicated document ID ${document.id}`);
    }
    this.documentsById[document.id] = document;
    this.miniSearch.add(document);
  }

  addAll(documents) {
    documents.forEach((document) => {
      this.addOne(document);
    });
  }

  async addAllAsync(documents) {
    await this.miniSearch.addAllAsync(documents);
    documents.forEach((d) => {
      this.documentsById[d.id] = d;
    });
  }

  search(query, options = {}) {
    return this.miniSearch
      .search(query, options)
      .map((result) => this.documentsById[result.id]);
  }

  autoSuggest(query, options = {}) {
    return this.miniSearch.autoSuggest(query, options);
  }
}

<script>
  export let props = {};
</script>

<svg
  class="inline-flex"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  width="24"
  height="24"
  fill="currentColor"
  {...props}
>
  <path
    d="M17 11a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1
    2 0v4h4z"
  />
</svg>

<script>
  import { createEventDispatcher } from "svelte";
  import * as api from "src/lib/api";

  export let value;
  export let loading = false;
  export let label = "";
  export let uploadPath = "";

  const dispatch = createEventDispatcher();

  async function dropHandler(ev) {
    ev.preventDefault();

    
    const files = [];
    for (const item of ev.dataTransfer.items) {
      if (item.kind === "file") {
        files.push(item.getAsFile());
      }
    }

    const linkText = await upload(files);
    ev.target.setRangeText(linkText);
    value = ev.target.value;
  }

  async function paste(ev) {
    if(ev?.clipboardData?.files?.length) {
      ev.preventDefault();
      const linkText = await upload(ev.clipboardData.files);
      ev.target.setRangeText(linkText);
      value = ev.target.value;
    }
  }

  async function upload(files) {
    const uploads = [];
    for (const file of files) {
        let upload;

        if(/^image/.test(file.type)) {
          upload = api.postImage(uploadPath, file)
            .then(({ id }) => {
              dispatch("upload", { id });
              return `![${file.name}](cloudflare:${id})`;
            });
        }
        else {
          upload = api.postFile(uploadPath, file)
            .then(({ id, name }) => {
              dispatch("upload", { id, name });
              return `![${name}](/api/files/${id})`;
            });
        }
        
        uploads.push(upload);
    }

    const links = await Promise.all(uploads);
    const linkText = links.join("\n");
    return linkText;
  }

  function dragOverHandler(ev) {
    ev.preventDefault();
  }
</script>

<div>
  <label class="block">
    <span class="text-gray-700">{label}</span>
    <textarea
      style="min-height:30vh"
      class="form-input"
      placeholder="(markdown)"
      bind:value
      required
      disabled={loading}
      on:drop={dropHandler}
      on:dragover={dragOverHandler}
      on:paste={paste}
    />
  </label>
  <div class="italic text-sm text-gray-500">
    <ul>
      <li>
        <a
          href="https://guides.github.com/features/mastering-markdown/"
          target="_blank">Markdown guide</a
        >
      </li>
      <li>
        Insert images by dragging and dropping them onto text area or via copy
        paste.
      </li>
    </ul>
  </div>
</div>
